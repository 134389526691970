<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Estatísticas</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0 d-flex">
        <b-button
          variant="outline-primary"
          class="action"
          @click="$emit('previous')"
        >
          <feather-icon
            size="18"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="title">
          {{ title }}
        </div>
        <b-button
          variant="outline-primary"
          class="action"
          :disabled="disabledNext"
          @click="$emit('next')"
        >
          <feather-icon
            size="18"
            icon="ChevronRightIcon"
          />
        </b-button>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.subtitle || item.icon"
          xl="3"
          sm="6"
          class="mb-1"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.action {
  padding: 2px 3px;
  margin-right: 5px;
  & ~ .action {
    margin-left: 5px;
  }
}
</style>
